import * as React from 'react';
import '../../_assets/css/file.css';
import Select from 'react-select';
import axios from 'axios';

import { TOKEN } from '../../_helpers/token';
import { baseURL } from '../../_helpers';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export class AddStation extends React.Component {

    constructor(props) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            phone_number: '',
            showError: false,
            isShowError: false,
        }
    }


    async componentDidMount() {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'signature': TOKEN
            },
        };
        const [countyResponse] = await Promise.all([
            axios.get(baseURL + 'role', config),
        ]);
        this.setState({
            isLoading: false,
            roles: countyResponse.data.roles,
        });
        console.log("BRIAN", this.state.roles)

    }
    ROLES() {
        return (this.state.roles && (this.state.roles.length > 0 || this.state.roles.length === 0) &&
            this.state.roles.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.id })))
    }

    onSelectRoles = value => {
        this.setState({ role_id: value.value.toString() });
    };

    onSubmit(e) {
        e.preventDefault();
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'signature': TOKEN
            },
        };
        let formData = {

            "name": this.state.name,
            
        }
        this.setState({ isLoading: true });
        axios.post(baseURL + 'station/add', formData, config)

            .then((response) => {
                console.log('bayoo', response.data)
                // eslint-disable-next-line
                if (response.data.code == "0000") {
                        this.setState({
                        statusMessage: response.data.message, 
                        isShowError: true, isLoading: false, showError: false,
                    });
                    
                    setTimeout(() => {
                        this.setState({
                        statusMessage: response.data.message, name: "", password: "", msisdn: "",
                        isShowError: false, isLoading: false, showError: false,
                    });
                    
                    }, 2000);


                } else {

                    this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                }
            }).catch(error => {
                console.log('bayoo', error.response)
                this.setState({
                    showError: true,
                    isShowError: false,
                    statusMessage: error.response.data.message,
                    submitted: true,
                    isLoading: false
                }
                    , function () {
                        // console.log('data', error.response.status_message)
                    });
            });

    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }



    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;

        return (
            <div style={{ marginTop: "-20px" }} >

                {showComponent && (
                    <div>

                        {this.state.showComponent ?
                            <peopleView /> : null
                        }

                    </div>
                )}

                {!hideComponent && (
                    <>
                        <Card>
                            <CardBody>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Create Station</h3>
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/station">
                                            <Button className="pull-right btn-sm"
                                                color="primary" outline>View Station</Button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="card__title">
                                    <h5 className="bold-text">Fill the below field to add station </h5>
                                </div>

                                {this.state.showError ? <div style={{ color: 'red' }}>
                                    {this.state.statusMessage}
                                </div> : null}
                                {this.state.isShowError ? (
                                    <div
                                        color="success"
                                        style={{ fontSize: "13px", color: "green" }}>
                                        {this.state.statusMessage}
                                    </div>

                                ) : null}<br></br>
                                <form className="form" onSubmit={this.onSubmit}>
                                    <div className="col-md-8 offset-2">
                                        <div className="form-group">
                                            <label className="form-label">Station Name</label>
                                            <input id="input" type="text" className="form-control input-md"
                                                name="name" required placeholder="Enter Station Name"
                                                value={this.state.name} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                 <div className="col-md-8 offset-2">
                                        <Button type="submit" color="primary" className="btn-paypal btn-lg pull-right  text-white " >
                                            {this.state.isLoading ? "Please Wait..." : "Submit"}</Button> &nbsp;&nbsp;&nbsp;
                                       </div>
                                </form>
                            </CardBody>
                        </Card>
                    </>
                )
                }

            </div>
        )

    }

}
