import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { ROLE, TOKEN } from '../../_helpers/token';
import { Link } from 'react-router-dom';
import { MDBCloseIcon } from "mdbreact"
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import * as moment from 'moment';
import Modal from 'react-modal';
import "./style.css"
import Alert from '../../shared/components/Alert';
import Select from 'react-select';
import leftimage from '../../_assets/img/homee.jpg'
  

export class OurWinners extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModalFloat = this.toggleModalFloat.bind(this);

        this.closeModalFloat = this.closeModalFloat.bind(this);
        this.columns2 = [

            {
                key: "mname",
                TrOnlyClassName: 'tsc',
                text: "Name",
                className: "tsc",
                align: "left"
            },
            {
                key: "msisd",
                TrOnlyClassName: 'tsc',
                text: "Phone No",
                className: "tsc",
                align: "left"
            },
         

            {
                key: "amount",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },
            {
                key: "date_created",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                align: "left"
            }


        ];

        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: false,
            show_pagination: true,
            pagination: 'advance',
            page_size: 1000,
            show_length_menu: false,
            show_info: false,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search...",
                no_data_text: "You don't have a winner",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            isOpenFloat: false,
            data: [],
        };
    }

    toggleModalFloat(record) {

        localStorage.setItem("winners_id", record.id)
        this.setState({
            isOpenFloat: true
        });
    };

    closeModalFloat = e => {
        this.setState({
            isOpenFloat: false,
        });
    };


    componentDidMount() {

        this.getData();
    }

    getData = (queryString = "") => {
        this.setState({ isLoading: true });
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'signature': TOKEN
            },
        };

        axios.get(baseURL + 'winner', config)
            .then((response) => {
                console.log("bayo", response.data)
                this.setState({
                    admins: response.data.winners,
                    total: response.data.winners.length,
                    isLoading: false
                },
                    function () {
                        var data = [];
                        for (let i = 0; i < this.state.admins.length; i++) {

                            let index = { idx: i + 1 };
                            let phone;
                            let mname;
                            let memail;


                            if (this.state.admins[i].msisdn == null) {
                                phone = { msisd: "_" };
                            } else {
                                phone = { msisd: this.state.admins[i].msisdn };
                            }
                            if (this.state.admins[i].email == null) {
                                memail = { memail: "_" };
                            } else {
                                memail = { memail: this.state.admins[i].email };
                            }

                            if (this.state.admins[i].name == null) {
                                mname = { mname: "_" };
                            } else {
                                mname = { mname: this.state.admins[i].name };
                            }

                            let mail

                            if (this.state.admins[i].awarded == true) {
                                mail = { mail: "Yes" };
                            } else {
                                mail = { mail: "No" };
                            }


                            data.push(Object.assign(mail, mname, memail, index, phone, this.state.admins[i]));
                            this.setState({
                                data: data
                            })
                            console.log("bugs", i);
                        }

                    }
                );
            })
    }


    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    Banks() {
        return (
            this.state.Bank &&
            (this.state.Bank.length > 0 || this.state.Bank.length === 0) &&
            this.state.Bank.map((countyItem, i) => ({
                label: countyItem.name,
                bank: countyItem.name,
                value: countyItem.code,
            }))
        );
    }
    onSelectChangesBank = value => {

        if (value != null) {

            this.setState(
                {
                    bank_code: value.value.toString(),
                    bank_name: value.label.toString()
                });
        }
    };





    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    render() {
        // console.log("Load", isLoading);
        return (
            <div className="col-md-12" style={{ backgroundImage: `url(${leftimage})`, backgroundColor: `white`, height: "100vh" }} >
                <Col>
                    <Card >
                        <CardBody >
                            <div className="panel-body" >
                                <Link to="/">
                                    <Button className="pull-left"
                                        color="primary" outline>Go Back?</Button><br />
                                </Link><br /> <br />
                                <div className="col-md-6">
                                    <h3>Our Winners</h3>
                                </div>

                                {this.state.isShowError ? (
                                    <div
                                        color="success"
                                        style={{ fontSize: "13px", color: "green" }}>
                                        {this.state.statusMessage}
                                    </div>

                                ) : null}<br></br>

                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.data}
                                    columns={this.columns2}
                                    dynamic={true}
                                    total_record={this.state.total}
                                    id="tsc"
                                    loading={this.state.isLoading}

                                />

                            </div>
                        </CardBody>

                    </Card>
                </Col>
            </div>

        )
    }
}