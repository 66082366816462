import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { ROLE, TOKEN } from '../../../_helpers/token';
import axios from 'axios';
import { baseURL } from '../../../_helpers';

class SidebarContent extends Component {
  static propTypes = {
    changeToDark: PropTypes.func.isRequired,
    changeToLight: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  hideSidebar = () => {
    const { onClick } = this.props;
    onClick();
  };
  componentDidMount() {
    this.setState({ isLoading: true });
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'signature': TOKEN
      },
    };
    axios.get(baseURL + 'user', config)
      .then((response) => {
        console.log("bayo", response.data)
        // eslint-disable-next-line
        if (response.data.code == "0045") {
          window.location.href = "/portal";
        }
      })
    if (TOKEN == null) {
      window.location.href = "/portal";
    }
  }


  logout = () => {
    window.location.href = "/portal";

    localStorage.removeItem('token');
  }
  render() {
    const { changeToLight, changeToDark } = this.props;
    return (
      <div className="sidebar__content">
        {ROLE === 'administrator' ?
          <>
            <ul className="sidebar__block">


              <SidebarCategory title="Presenters" icon="users">
                <SidebarLink title="Presenters" route="/user" onClick={this.hideSidebar} />
                <SidebarLink title="Presenter Sessions" route="/session" onClick={this.hideSidebar} />
              </SidebarCategory>
              <SidebarLink
                title="Station"
                icon="apartment"
                route="/station"
                onClick={this.hideSidebar}
              />

             
            

              <SidebarLink
                title="Winners"
                icon="bullhorn"
                route="/winners"
                onClick={this.hideSidebar}
              />

              <SidebarLink
                title="Reconciliation"
                icon="book"
                route="/reconcile"
                onClick={this.hideSidebar}
              />


            </ul>
            {/* <ul className="sidebar__block">

              <SidebarCategory title="Layout" icon="layers">
                <button className="sidebar__link" type="button" onClick={changeToLight}>
                  <p className="sidebar__link-title">Light Theme</p>
                </button>
                <button className="sidebar__link" type="button" onClick={changeToDark}>
                  <p className="sidebar__link-title">Dark Theme</p>
                </button>
              </SidebarCategory>
            </ul> */}
          </>
          : null}
        {ROLE === 'presenter' ?
          <>
            <SidebarLink
              title="Deposits"
              icon="briefcase"
              route="/deposits"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title="Run Draw"
              icon="bullhorn"
              route="/winnerdraw"
              onClick={this.hideSidebar}
            />
            <SidebarLink
              title="Winners"
              icon="bullhorn"
              route="/winner"
              onClick={this.hideSidebar}
            />

            

          </>
          : null}
        <ul className="sidebar__block">
          <SidebarCategory title="Layout" icon="layers">
            <button className="sidebar__link" type="button" onClick={changeToLight}>
              <p className="sidebar__link-title">Light Theme</p>
            </button>
            <button className="sidebar__link" type="button" onClick={changeToDark}>
              <p className="sidebar__link-title">Dark Theme</p>
            </button>
          </SidebarCategory>
        </ul>
        <ul className="sidebar__block">
          <SidebarLink title="LogOut" icon="exit" onClick={this.logout} route="/log_in" />
        </ul>

      </div>

    );
  }
}

export default SidebarContent;
