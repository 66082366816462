import * as React from 'react';
import '../../_assets/css/file.css';
import Select from 'react-select';
import axios from 'axios';

import { TOKEN } from '../../_helpers/token';
import { baseURL } from '../../_helpers';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { Link } from 'react-router-dom';

export class UserSearch extends React.Component {

    constructor(props) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            phone_number: '',
            showError: false,
            isShowError: false,
        }
    }


    Banks() {
        return (
            this.state.stations &&
            (this.state.stations.length > 0 || this.state.stations.length === 0) &&
            this.state.stations.map((countyItem, i) => ({
                label: countyItem.name,
                value: countyItem.id,
            }))
        );
    }
    onSelectChangesBank = value => {

        if (value != null) {

            this.setState(
                {
                    station_id: value.value.toString(),
                    station_name: value.label.toString()
                });
        }
    };


    async componentDidMount() {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'signature': TOKEN
            },
        };
        const [countyResponse, stationResponse] = await Promise.all([
            axios.get(baseURL + 'role', config),
            axios.get(baseURL + 'station', config),

        ]);
        this.setState({
            isLoading: false,
            roles: countyResponse.data.roles,
            stations: stationResponse.data.stations
        });
        console.log("BRIAN", this.state.roles)

    }
    ROLES() {
        return (this.state.roles && (this.state.roles.length > 0 || this.state.roles.length === 0) &&
            this.state.roles.map((countyItem, i) =>
                ({ label: countyItem.name, value: countyItem.id })))
    }

    onSelectRoles = value => {
        this.setState({ role_id: value.value.toString() });
    };

    onSubmit(e) {
        e.preventDefault();
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'signature': TOKEN
            },
        };
        let formData = {

            "username": this.state.username,
            "password": this.state.password,
            "msisdn": this.state.msisdn,
            "station_id": this.state.station_id,

            "role_id": this.state.role_id
        }
        this.setState({ isLoading: true });
        axios.post(baseURL + 'user/add', formData, config)

            .then((response) => {
                console.log('bayoo', response.data)
                // eslint-disable-next-line
                if (response.data.code == "0000") {
                    this.setState({
                        statusMessage: response.data.message, username: "", password: "", msisdn: "",
                        isShowError: true, isLoading: false, showError: false,
                    });
                    setTimeout(() => {
                        this.setState({
                            statusMessage: response.data.message, username: "", password: "", msisdn: "",
                            isShowError: false, isLoading: false, showError: false,
                        });
                    }, 2000);


                } else {

                    this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                }
            }).catch(error => {
                console.log('bayoo', error.response)
                this.setState({
                    showError: true,
                    isShowError: false,
                    statusMessage: error.response.data.message,
                    submitted: true,
                    isLoading: false
                }
                    , function () {
                        // console.log('data', error.response.status_message)
                    });
            });

    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }



    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;

        return (
            <div style={{ marginTop: "-20px" }} >

                {showComponent && (
                    <div>

                        {this.state.showComponent ?
                            <peopleView /> : null
                        }

                    </div>
                )}

                {!hideComponent && (
                    <>
                        <Card>
                            <CardBody>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Create Presenter</h3>
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/user">
                                            <Button className="pull-right"
                                                color="primary" outline>View Presenters</Button>
                                        </Link>
                                    </div>
                                </div>
                                <div className="card__title">
                                    <h5 className="bold-text">Fill the Below Field to add user </h5>
                                </div>

                                {this.state.showError ? <div style={{ color: 'red' }}>
                                    {this.state.statusMessage}
                                </div> : null}
                                {this.state.isShowError ? (
                                    <div
                                        color="success"
                                        style={{ fontSize: "13px", color: "green" }}>
                                        {this.state.statusMessage}
                                    </div>

                                ) : null}<br></br>
                                <form className="form" onSubmit={this.onSubmit}>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Username</label>
                                            <input id="input" type="text" className="form-control input-md"
                                                name="username" required placeholder="Enter Username"
                                                value={this.state.username} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Phone Number</label>
                                            <input id="input" type="text" className="form-control input-md"
                                                name="msisdn" required placeholder="Enter Phone Number"
                                                value={this.state.msisdn} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Station</label>

                                            <Select
                                                isClearable
                                                options={this.Banks()}
                                                // onInputChange={this.loadOptions}
                                                placeholder="Search Station"
                                                autosize={true}
                                                onChange={this.onSelectChangesBank}
                                                isLoading={this.state.isLoading}
                                                // noOptionsMessage={() => 'nothing found'}
                                                // loadingMessage={() => 'searching...'}   //minor type-O here
                                                menuPortalTarget={document.body}
                                                name="namffe"
                                            /><br />

                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Select Roles</label>
                                            <Select
                                                options={this.ROLES()}
                                                onChange={this.onSelectRoles}
                                                placeholder="Select Roles"
                                                tabSelectsValue={false}
                                                value={this.state.onSelectChange}
                                                className='drop'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">password</label>
                                            <input id="input" type="text" className="form-control input-md"
                                                name="password" required placeholder="Enter password"
                                                value={this.state.password} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <Button type="submit" color="primary" className="btn-paypal btn-lg pull-right  text-white " >
                                            {this.state.isLoading ? "Please Wait..." : "Submit"}</Button> &nbsp;&nbsp;&nbsp;
                                       </div>
                                </form>
                            </CardBody>
                        </Card>
                    </>
                )
                }

            </div>
        )

    }

}
