export default [
  {
    title: 'Invoice template',
    link: 'http://www.admin-easydev/invoice',
    preview: 'Breakfast agreeable incommode departure it an. By ignorant '
    + 'at on wondered relation. Enough at tastes '
    + 'really so cousin am of. Extensive therefore supported'
    + ' by extremity of contented. Is pursuit compact demesne '
    + 'invited elderly be. View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns…',
  },
  {
    title: 'Open Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'View him she roof tell her case has sigh. Moreover '
    + 'is possible he admitted sociable concerns… ',
  },
  {
    title: 'Some … Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'By ignorant at on wondered relation. Enough at tastes '
    + 'really so cousin am of. Extensive therefore '
    + 'supported by extremity of contented. Is pursuit compact demesne '
    + 'invited elderly be. View him she roof tell her '
    + 'case has sigh. Moreover is possible he admitted sociable concerns… ',
  },
  {
    title: 'Invoice template',
    link: 'http://www.admin-easydev/invoice',
    preview: 'Breakfast agreeable incommode departure it an. By ignorant '
    + 'at on wondered relation. Enough at tastes '
    + 'really so cousin am of. Extensive therefore supported by extremity '
    + 'of contented. Is pursuit compact demesne '
    + 'invited elderly be. View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns…',
  },
  {
    title: 'Open Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns… ',
  },
  {
    title: 'Some … Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'By ignorant at on wondered relation. Enough at '
    + 'tastes really so cousin am of. Extensive therefore '
    + 'supported by extremity of contented. Is pursuit compact '
    + 'demesne invited elderly be. View him she roof tell '
    + 'her case has sigh. Moreover is possible he admitted sociable concerns… ',
  },
  {
    title: 'Invoice template',
    link: 'http://www.admin-easydev/invoice',
    preview: 'Breakfast agreeable incommode departure it an. '
    + 'By ignorant at on wondered relation. Enough at tastes '
    + 'really so cousin am of. Extensive therefore supported '
    + 'by extremity of contented. Is pursuit compact demesne '
    + 'invited elderly be. View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns…',
  },
  {
    title: 'Open Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns… ',
  },
  {
    title: 'Some … Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'By ignorant at on wondered relation. Enough '
    + 'at tastes really so cousin am of. Extensive therefore '
    + 'supported by extremity of contented. Is pursuit compact '
    + 'demesne invited elderly be. View him she roof tell '
    + 'her case has sigh. Moreover is possible he admitted sociable concerns… ',
  },
  {
    title: 'Invoice template',
    link: 'http://www.admin-easydev/invoice',
    preview: 'Breakfast agreeable incommode departure it an. '
    + 'By ignorant at on wondered relation. Enough at tastes '
    + 'really so cousin am of. Extensive therefore supported '
    + 'by extremity of contented. Is pursuit compact demesne '
    + 'invited elderly be. View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns…',
  },
  {
    title: 'Open Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns… ',
  },
  {
    title: 'Some … Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'By ignorant at on wondered relation. Enough at '
    + 'tastes really so cousin am of. Extensive therefore '
    + 'supported by extremity of contented. Is pursuit '
    + 'compact demesne invited elderly be. View him she roof tell '
    + 'her case has sigh. Moreover is possible he admitted sociable concerns… ',
  },
  {
    title: 'Invoice template',
    link: 'http://www.admin-easydev/invoice',
    preview: 'Breakfast agreeable incommode departure it an. '
    + 'By ignorant at on wondered relation. Enough at tastes '
    + 'really so cousin am of. Extensive therefore supported '
    + 'by extremity of contented. Is pursuit compact demesne '
    + 'invited elderly be. View him she roof tell her case has '
    + 'sigh. Moreover is possible he admitted sociable concerns…',
  },
  {
    title: 'Open Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'View him she roof tell her case has sigh. '
      + 'Moreover is possible he admitted sociable concerns… ',
  },
  {
    title: 'Some … Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'By ignorant at on wondered relation. '
    + 'Enough at tastes really so cousin am of. Extensive therefore '
    + 'supported by extremity of contented. Is pursuit compact '
    + 'demesne invited elderly be. View him she roof tell '
    + 'her case has sigh. Moreover is possible he admitted sociable concerns… ',
  },
  {
    title: 'Invoice template',
    link: 'http://www.admin-easydev/invoice',
    preview: 'Breakfast agreeable incommode departure it an. '
    + 'By ignorant at on wondered relation. Enough at tastes '
    + 'really so cousin am of. Extensive therefore supported '
    + 'by extremity of contented. Is pursuit compact demesne '
    + 'invited elderly be. View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns…',
  },
  {
    title: 'Open Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'View him she roof tell her case has sigh. '
    + 'Moreover is possible he admitted sociable concerns… ',
  },
  {
    title: 'Some … Invoice template',
    link: 'http://www.admin-easydev/invoice_open',
    preview: 'By ignorant at on wondered relation. '
    + 'Enough at tastes really so cousin am of. Extensive therefore '
    + 'supported by extremity of contented. Is pursuit compact '
    + 'demesne invited elderly be. View him she roof tell '
    + 'her case has sigh. Moreover is possible he admitted sociable concerns… ',
  },
];
