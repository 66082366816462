import React from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import "../../_assets/css/file.css"
import { Card, CardBody, Col } from 'reactstrap';
import { TOKEN } from '../../_helpers/token';
import { baseURL } from '../../_helpers';
import RefreshIcon from 'mdi-react/RefreshCircleIcon';

export class Customers extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [
            {
                key: "msisdn",
                TrOnlyClassName: 'tsc',
                text: "Phone#",
                className: "tsc",
                align: "left"
            },
            {
                key: "email",
                TrOnlyClassName: 'tsc',
                text: "Email",
                className: "tsc",
                align: "left"
            },
            {
                key: "amount",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },
            {
                key: "date_created",
                TrOnlyClassName: 'tsc',
                text: "Date Created",
                className: "tsc",
            }
        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: false,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search...",
                no_data_text: "No data was found",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = (queryString = "") => {
        this.setState({ isLoading: true });
        // alert(TOKEN)
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'signature': TOKEN
            },
        };
        let url = baseURL + "deposit?" + queryString;
        console.log("query", url);


        axios.all([
            axios.get(url, config)
            // axios.get(baseURL + "count"),
        ]).then(axios.spread((branchResponse) => {

            // alert(JSON.stringify(branchResponse.data))
            this.setState({
                admins: branchResponse.data,
                isLoading: false,
            },


                function () {
                    var data = [];

                    console.log("bugs", this.state.admins);

                    console.log("BUG", this.state.admins.deposits)
                    if (this.state.admins.deposits.length > 0) {

                        for (let i = 0; i < this.state.admins.deposits.length; i++) {
                            let email_address;

                            let phone;
                            let mname;
                            let memail;


                            if (this.state.admins.deposits[i].msisdn == null) {
                                phone = { msisd: "_" };
                            } else {
                                phone = { msisd: this.state.admins.deposits[i].msisdn };
                            }





                            if (this.state.admins.deposits[i].email == null) {
                                email_address = { email_address: "_" }
                            } else {
                                email_address = { email_address: this.state.admins.deposits[i].email }
                            }
                            data.push(Object.assign(email_address,phone, this.state.admins.deposits[i]));
                            this.setState({
                                data: data
                            })
                        }

                    } else {
                        this.setState({
                            data: data
                        })

                    }
                }
            );
        }))

    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    render() {
        return (
            <div style={{ marginTop: "-20px" }} >
                < >
                    < Card >
                        <CardBody >
                            <div className="panel-body" >

                                <div className="row">
                                    <div className="col-md-6">
                                        <h4> <b>Deposits</b></h4><br />
                                    </div>
                                    <div className="col-6">
                                        <button className="float-right" title="refresh" style={{ background: "white", border: "0px" }}
                                            onClick={this.getData}>
                                            <RefreshIcon />
                                        </button>
                                    </div>
                                </div>
                                <br />
                                <div className="">
                                    Total Amount as at Now: <b>{this.state.admins.total_amount}</b> || Total Counts: <b>{this.state.admins.total_count}</b>
                                    <br /><br />
                                </div>
                                {this.state.isShowError ?
                                    <div className="alert alert-success" > {this.state.statusMessage}
                                    </div> : null
                                }
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.admins.deposits}
                                    columns={this.columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={this.state.isLoading}
                                    total_record={this.state.admins.total_count}
                                    onChange={this.tableChangeHandler} />
                            </div>
                        </CardBody>

                    </Card>
                </>
            </div>
        )
    }
}