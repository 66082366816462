import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { ROLE, TOKEN } from '../../_helpers/token';
import { Link } from 'react-router-dom';
import { MDBCloseIcon } from "mdbreact"
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import * as moment from 'moment';
import Modal from 'react-modal';
import "./style.css"
import Alert from '../../shared/components/Alert';
import Select from 'react-select';


export class WinnersPresenters extends React.Component {
    constructor(props) {
        super(props);
        this.toggleModalFloat = this.toggleModalFloat.bind(this);
        this.onSubmit = this.onSubmit.bind(this);

        this.closeModalFloat = this.closeModalFloat.bind(this);
        this.columns2 = [

            {
                key: "mname",
                TrOnlyClassName: 'tsc',
                text: "Name",
                className: "tsc",
                align: "left"
            },
            {
                key: "msisd",
                TrOnlyClassName: 'tsc',
                text: "Phone No",
                className: "tsc",
                align: "left"
            },
            {
                key: "memail",
                TrOnlyClassName: 'tsc',
                text: "Email",
                className: "tsc",
                align: "left"
            },
            {
                key: "mail",
                TrOnlyClassName: 'tsc',
                text: "Awarded",
                className: "tsc",
                align: "left",
                cell: record => {
                    return (
                        <Fragment className="center" style={{ fontSize: "1em", color: "white" }}>
                            {record.mail === 'Yes' ?
                                < span class="badge-success" style={{ fontSize: "14px", borderRadius: "5px", padding: "2px", color: "white" }} >
                                    {record.mail}
                                </ span>
                                : null}

                            {record.mail === 'No' ?
                                < span class="badge-danger" style={{ fontSize: "14px", borderRadius: "5px", padding: "2px", color: "white" }}>
                                    {record.mail}
                                </ span>
                                : null}
                        </Fragment >
                    );
                }
            },


            {
                key: "amount",
                TrOnlyClassName: 'tsc',
                text: "Amount",
                className: "tsc",
                align: "left"
            },
            {
                key: "date_created",
                TrOnlyClassName: 'tsc',
                text: "Date",
                className: "tsc",
                align: "left"
            }


        ];

        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: false,
            show_pagination: true,
            pagination: 'advance',
            page_size: 1000,
            show_length_menu: false,
            show_info: false,
            language: {
                loading_text: "Please be patient while data loads...",
                filter: "Search...",
                no_data_text: "You don't have a winner",
                pagination: {
                    next: <span>&#9658;</span>,
                    previous: <span>&#9668;</span>
                    // next
                    // previous
                }
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            isOpenFloat: false,
            data: [],
        };
    }

    toggleModalFloat(record) {

        localStorage.setItem("winners_id", record.id)
        this.setState({
            isOpenFloat: true
        });
    };

    closeModalFloat = e => {
        this.setState({
            isOpenFloat: false,
        });
    };


    componentDidMount() {

        this.getData();
    }

    getData = (queryString = "") => {
        this.setState({ isLoading: true });
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'signature': TOKEN
            },
        };
        axios.get(baseURL + 'winner', config)
            .then((response) => {
                console.log("bayo", response.data)
                this.setState({
                    admins: response.data.winners,
                    total: response.data.winners.length,
                    isLoading: false
                }, function () {
                    var data = [];
                    for (let i = 0; i < this.state.admins.length; i++) {
                        let index = { idx: i + 1 };
                        let phone;
                        let mname;
                        let memail;
                        if (this.state.admins[i].msisdn == null) {
                            phone = { msisd: "_" };
                        } else {
                            phone = { msisd: this.state.admins[i].msisdn };
                        }
                        if (this.state.admins[i].email == null) {
                            memail = { memail: "_" };
                        } else {
                            memail = { memail: this.state.admins[i].email };
                        }
                        if (this.state.admins[i].name == null) {
                            mname = { mname: "_" };
                        } else {
                            mname = { mname: this.state.admins[i].name };
                        }
                        let mail
                        if (this.state.admins[i].awarded == true) {
                            mail = { mail: "Yes" };
                        } else {
                            mail = { mail: "No" };
                        }
                        data.push(Object.assign(mail, mname, memail, index, phone, this.state.admins[i]));
                        this.setState({
                            data: data
                        })
                        console.log("bugs", i);
                    }
                }
                );
            })
    }


    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }

    Banks() {
        return (
            this.state.Bank &&
            (this.state.Bank.length > 0 || this.state.Bank.length === 0) &&
            this.state.Bank.map((countyItem, i) => ({
                label: countyItem.name,
                bank: countyItem.name,
                value: countyItem.code,
            }))
        );
    }
    onSelectChangesBank = value => {

        if (value != null) {

            this.setState(
                {
                    bank_code: value.value.toString(),
                    bank_name: value.label.toString()
                });
        }
    };





    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onDelete(record) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'signature': TOKEN
            }
        };
        let id = record.id

        let formData = {

            "user_id": id.toString(),
        }
        axios.post(baseURL + "user/delete", formData, config).then(response => {
            // eslint-disable-next-line
            if (response.data.code == "0000") {
                this.setState({
                    statusMessage: response.data.message,
                    isShowError: true, isLoading: false, showError: false,
                });
                window.setTimeout(function () {
                    window.location.reload();
                }, 2000);

            } else {
                this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
            }
        }).catch(error => {
            console.log('bayoo', error.response)
            this.setState({
                showError: true,
                isShowError: false,
                statusMessage: error.response.data.message,
                submitted: true,
                isLoading: false
            }
                , function () {
                    // console.log('data', error.response.status_message)
                });
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }


    onSubmit(e) {
        e.preventDefault();


        let formData = {
            "winner_id": localStorage.getItem("winners_id"),
            "bank_code": this.state.bank_code,
            "bank_account": this.state.bank_account_no
        }

        // alert(JSON.stringify(formData))

        this.setState({ isLoading: true });
        axios.post(baseURL + 'initiate_withdrawal', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'signature': TOKEN
            },
        }).then((response) => {
            console.log('bayoo', response.data)
            if (response.data.code === "0000") {
                this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
                window.scrollTo(0, 0)
                window.setTimeout(() => {
                    this.setState({ isShowError: false });
                    this.closeModalFloat();
                }, 2000);
            } else {
                this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });

                window.setTimeout(() => {
                    this.setState({
                        isLoading: false,
                        isShowError: false,
                        statusMessage: "",
                        showError: false
                    });
                }, 2000);
            }
        }).catch((error) => {
            console.log('bayoo', error)
            this.setState({
                statusMessage: error.response === undefined ? "Check your Internet" : error.response.data.message, showError: true, isShowError: false, isLoading: false
            });
            window.setTimeout(() => {
                this.setState({
                    isLoading: false,
                    isShowError: false,
                    showError: false,
                    errorShow: false
                });
            }, 2000);
        })
    }


    render() {
        // console.log("Load", isLoading);
        return (
            <div style={{ marginTop: "-20px" }} >
                < >

                    <Modal
                        isOpen={this.state.isOpenFloat}
                        onRequestClose={e => {
                            this.closeModalFloat(e);
                        }}
                        contentLabel="My dialog"
                        className="mymodalclient"
                        onAfterOpen={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onAfterClose={() => {
                            document.body.removeAttribute('style');
                        }}
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                    >
                        <MDBCloseIcon onClick={this.closeModalFloat} />
                        <h4><b>Initiate Withdrawal</b></h4><br />
                        {this.state.showError ? <div >
                            <Alert color="danger" className="alert--colored" icon>
                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                </p>
                            </Alert>
                        </div> : null}
                        {this.state.isShowError ? (
                            <div>
                                <Alert color="success" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>
                            </div>
                        ) : null}
                        <br></br>
                        <form className="form" onSubmit={this.onSubmit}>
                            <div className="col-md-12">
                                <div className="form-group">

                                    <Select
                                        isClearable
                                        options={this.Banks()}
                                        // onInputChange={this.loadOptions}
                                        placeholder="Search Bank"
                                        autosize={true}
                                        onChange={this.onSelectChangesBank}
                                        isLoading={this.state.isLoading}
                                        // noOptionsMessage={() => 'nothing found'}
                                        // loadingMessage={() => 'searching...'}   //minor type-O here
                                        menuPortalTarget={document.body}
                                        name="namffe"
                                    /><br />

                                </div>
                            </div>


                            <div className="col-md-12">
                                <div className="form-group">
                                    <input id="input" type="text" className="form-control"
                                        name="bank_account_no" placeholder='Bank Account No.'
                                        value={this.state.bank_account_no} onChange={this.handleChange} />

                                </div>
                            </div>

                            <div className="col-md-12 text-center"><br />
                                <Button type="submit" color="primary" outline className="btn-paypal btn-sm" >
                                    {this.state.isLoading ? "Please Wait..." : "Submit"}

                                </Button> &nbsp;&nbsp;&nbsp;
                                    </div>
                        </form>
                    </Modal>

                    
                    < Card >
                        <CardBody >
                            <div className="panel-body" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Winner</h3>
                                    </div>
                                </div>
                                {this.state.isShowError ? (
                                    <div
                                        color="success"
                                        style={{ fontSize: "13px", color: "green" }}>
                                        {this.state.statusMessage}
                                    </div>

                                ) : null}<br></br>

                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.data}
                                    columns={this.columns2}
                                    dynamic={true}
                                    total_record={this.state.total}
                                    id="tsc"
                                    loading={this.state.isLoading}

                                />

                            </div>
                        </CardBody>

                    </Card>
                </>
            </div>

        )
    }
}