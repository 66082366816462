import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../Layout/index';
import Commerce from './Commerce';
import Crypto from './Crypto';
import Documentation from './Documentation';
import DefaultPages from './DefaultPages';
import ECommerce from './ECommerce';
import Maps from './Maps';
import Charts from './Charts';
import Tables from './Tables';
import Forms from './Forms';
import Chat from '../../../Chat/index';
import Todo from '../../../Todo/index';
import FitnessDashboard from '../../../Dashboards/Fitness/index';
import MobileAppDashboard from '../../../Dashboards/MobileApp/index';
import BookingDashboard from '../../../Dashboards/Booking/index';
import Mail from '../../../Mail/index';
import { Customers } from '../../../Customers/customers';
import { UserSearch } from '../../../users/usersearch';
import { Users } from '../../../users/Users';
import { UserSession } from '../../../users/session';
import { SessionList } from '../../../users/sessionlist';
import { EditSession } from '../../../users/editsession';
import { EditUser } from '../../../users/edituser';
import { AddStation } from '../../../users/addstation';
import { Station } from '../../../users/station';
import { Winners } from '../../../users/winners';
import { WinnersPresenters } from '../../../users/winners_presenters';
import { WinnerDraw } from '../../../users/winnerdraw';
import FileUploads from '../../../CSV';


export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/dashboard_e_commerce" component={Commerce} />
      <Route path="/dashboard_fitness" component={FitnessDashboard} />
      <Route path="/dashboard_crypto" component={Crypto} />
      <Route exact path="/dashboard_mobile_app" component={MobileAppDashboard} />
      <Route path="/dashboard_booking" component={BookingDashboard} />
      <Route path="/deposits" component={Customers} />
      <Route path="/createuser" component={UserSearch} />
      <Route path="/user" component={Users} />
      <Route path="/session" component={SessionList} />
      <Route path="/createsession" component={UserSession} />
      <Route path="/editsession" component={EditSession} />
      <Route path="/edituser" component={EditUser} />


      <Route path="/addstation" component={AddStation} />

      <Route path="/station" component={Station} />
      <Route path="/winners" component={Winners} />

      <Route path="/winner" component={WinnersPresenters} />

      <Route path="/winnerdraw" component={WinnerDraw} />
      <Route path="/reconcile" component={FileUploads} />

      <Route path="/mail" component={Mail} />
      <Route path="/chat" component={Chat} />
      <Route path="/todo" component={Todo} />
      <Route path="/forms" component={Forms} />
      <Route path="/tables" component={Tables} />
      <Route path="/charts" component={Charts} />
      <Route path="/maps" component={Maps} />
      <Route path="/e-commerce" component={ECommerce} />
      <Route path="/default_pages" component={DefaultPages} />
      <Route path="/documentation" component={Documentation} />
    </div>
  </div>
);
