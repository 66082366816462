/* eslint-disable max-len */
import React, { PureComponent } from 'react';
import { Col, Row, Container } from 'reactstrap';
import scrollToComponent from 'react-scroll-to-component';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import CardIcon from 'mdi-react/CardIcon';
import PhoneOutlineIcon from 'mdi-react/PhoneOutlineIcon';
import { changeThemeToDark, changeThemeToLight } from '../../../redux/actions/themeActions';
import { ThemeProps } from '../../../shared/prop-types/ReducerProps';
import Modal from 'react-modal';
import { NavLink } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Button } from 'reactstrap';
import { MDBCloseIcon } from "mdbreact"
import { baseURL } from '../../../_helpers';
import axios from 'axios';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import EyeIcon from 'mdi-react/EyeIcon';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import Alert from '../../../shared/components/Alert';
import { Card } from '@material-ui/core';
import img from '../../../_assets/img/logbo.png'

const logo = `${process.env.PUBLIC_URL}/img/landing/logbo.png`;

class LogIn extends PureComponent {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
    this.state = {
      isOpen1: false,
      email_address: "",
      password: "",
      msisdn: "",
      showAgentReset: false,
      showPassword: false,
      setShowPassword: false,
      channel_type: "portal",
      error: "",
      errorMsg: "",
      showAddAgent: false,
      showOTP: false,
      role: "",
      otpConfirmation: false,
      loginshow: true,
      id: "",
      user: [],
      statusMessage: "",
      isShowSuccess: false,
      submitted: false,
      alert_error_color: "",
      isLoggedIn: false,
      isChecked: false,
      errorShow: false,
      successShow: false,
      statis: []
    };
  }

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    theme: ThemeProps.isRequired,
  };


  changeToDark = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToDark());
  };
  handleClick = () => {
    if (this.state.click) {
      this.setState({
        click: false
      })
    } else {
      this.setState({
        click: true
      })
    }
  }
  changeToLight = () => {
    const { dispatch } = this.props;
    dispatch(changeThemeToLight());
  };
  handleChangeEmail = event => {
    this.setState({ email_address: event.target.value });
  };
  handleChangePassword = event => {
    this.setState({ password: event.target.value });
  };


  showPasswordToggle = () => {
    // eslint-disable-next-line
    if (this.state.showPassword == true) {
      this.setState({
        showPassword: false,
      })
    } else {
      this.setState({
        showPassword: true,
      })
    }
  };

  onSubmit = e => {
    e.preventDefault();
    this.setState({ isLoading: true });
    if (this.state.email_address === "" || this.state.password === "") {
      this.setState({
        isLoading: false,
        error: "Username and password is required",
        errorShow: true,
        submitted: true,
        alert_error_color: "alert alert-danger"
      });
    } else {
      let formData = {
        "username": this.state.email_address,
        "password": this.state.password,
      }
      console.log("DATA", formData)
      this.setState({ isLoading: true });
      axios.post(baseURL + 'login', formData, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        },
      })
        .then((response) => {
          if (response.data.code === "0000") {
            this.setState({
              statusMessage: "Login Success! Redirecting....",
              isShowError: true,
              errorShow: false,
              submitted: true,
              isLoggedIn: true
            });
            localStorage.setItem("user_role", response.data.role);
            localStorage.setItem("token", response.data.token);
            localStorage.setItem("name", response.data.name);

            // eslint-disable-next-line
            if (response.data.role == "administrator") {
              window.setTimeout(function () {
                window.location.href = "/session";
                this.setState({ isLoading: false });
              }, 2000);

            }
            else {
              window.setTimeout(function () {
                window.location.href = "/deposits";
                this.setState({ isLoading: false });
              }, 2000);
            }
          }
          else {
            console.log("bayoo", response.data)
            this.setState({
              alert_error_color: "alert alert-danger",
              errorShow: true,
              error: response.data.message,
              submitted: true,
              isLoading: false
            }, function () {
              console.log('data', response.data.status)
            });
          }
        }).catch(error => {
          console.log('bao', error.response)
          this.setState({
            alert_error_color: "alert alert-danger",
            errorShow: true,
            error: error.response.data.message,
            submitted: true,
            isLoading: false
          }
            , function () {
              // console.log('data', error.response.status_message)
            });
        });
      this.setState({ password: "", email_address: "" });
    }
  };



  render() {
    const { theme } = this.props;
    const { submitted, showPassword, errorShow, otpConfirmation, showAgentReset, showAddAgent, showOTP, loginshow } = this.state;

    return (

      <div className="landing">
        <div className="elite-login">
          <div className="elite-login-sec">
            <div className="row">
              <div className="col-md-6 elite-login-left">
                <div className="carousel-wrap">
                  <div className="item">
                    <div className="item-sec">
                      <div className="login_slider_image"><img src={img} /></div>
                      <p className="item-text" style={{ color: "white" }}></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 elite-login-right">
                <h1 className="elite-login-head">Login Page</h1>

                <form className="elite-login-form"  onSubmit={this.onSubmit} >
                  {this.state.isShowError ? (
                    <Alert color="success" className="alert--colored" >
                      <span>
                        {this.state.statusMessage}
                      </span>
                    </Alert>
                  ) : null}
                  {errorShow && (
                    <div>
                      <div
                        color="red"
                        style={{ fontSize: "13px", color: "red" }}>
                        {this.state.error}
                      </div>
                    </div>
                  )}
                  <div className="elite-form-field">
                    <label htmlFor="user_email">Your username</label>
                    <input className="form-control"
                      placeholder="Enter Username"
                      id="elite-username"
                      required="required"
                      type="text"
                      onChange = {this.handleChangeEmail}
                      style={{ borderColor: "grey" }}
                      value={this.state.email_address}
                      name="email_address" />
                  </div>
                  <div className="elite-form-field">
                    <label htmlFor="user_email">Password</label>
                    <input placeholder="Enter your password"
                      id="elite-email"
                      className="form-control"
                      required="required"
                      style={{ borderColor: "grey" }}
                      value={this.state.password}
                      onChange={this.handleChangePassword}
                      type="password"
                    />
                    <br /><br />

                    <br /><br />
                    <br /><br />
                    {/* <input type="hidden" name="user_timezone" id="user_timezone" />
                    <span toggle="#password-field" className="fa fa-fw fa-eye field-icon toggle-password" /> */}
                  </div>


                  <button type="submit" className="elite-form-btn"> {
                    this.state.isLoading ? "Please wait..." : "Sign In"}
                  </button>
                </form>
              </div>
              {/* <p onClick={this.resetpasswordchange}>Forgot Password?</p><br></br> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => ({ theme: state.theme }))(LogIn);
