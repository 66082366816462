import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import { Link } from 'react-router-dom';
import { MDBCloseIcon } from "mdbreact"
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import * as moment from 'moment';
import Modal from 'react-modal';
import "./style.css"
import Alert from '../../shared/components/Alert';
import Select from 'react-select';


export class WinnerDraw extends React.Component {
    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);

        this.closeModalFloat = this.closeModalFloat.bind(this);
        this.state = {
            isLoading: false,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            isOpenFloat: true,
            data: [],
        };
    }

    closeModalFloat = e => {
        this.setState({
            isOpenFloat: false,
        });
    };


    onSubmit(e) {
        e.preventDefault();

        this.setState({ isLoading: true });
        axios.get(baseURL + 'run_draw', {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'signature': TOKEN
            },
        }).then((response) => {
            console.log('bayoo', response.data)
            if (response.data.code === "0000") {
                this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
                window.scrollTo(0, 0)
                window.setTimeout(() => {
                    this.setState({ isShowError: false });
                    window.location.href = "winner"
                }, 2000);
            } else {
                this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });

                window.setTimeout(() => {
                    this.setState({
                        isLoading: false,
                        isShowError: false,
                        statusMessage: "",
                        showError: false
                    });
                }, 2000);
            }
        }).catch((error) => {
            console.log('bayoo', error)
            this.setState({
                statusMessage: error.response === undefined ? "Check your Internet" : error.response.data.message, showError: true, isShowError: false, isLoading: false
            });
            window.setTimeout(() => {
                this.setState({
                    isLoading: false,
                    isShowError: false,
                    showError: false,
                    errorShow: false
                });
            }, 2000);
        })
    }


    render() {
        // console.log("Load", isLoading);
        return (
            <div style={{ marginTop: "-20px" }} >
                < >

                    <Modal
                        isOpen={this.state.isOpenFloat}

                        contentLabel="My dialog"
                        className="mymodalclient"
                        onAfterOpen={() => {
                            document.body.style.overflow = 'hidden';
                        }}
                        onAfterClose={() => {
                            document.body.removeAttribute('style');
                        }}
                        overlayClassName="myoverlay"
                        closeTimeoutMS={500}
                    >
                        <h3><b>Run Draw</b></h3><br />
                        {this.state.showError ? <div >
                            <Alert color="danger" className="alert--colored" icon>
                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                </p>
                            </Alert>
                        </div> : null}
                        {this.state.isShowError ? (
                            <div>
                                <Alert color="success" className="alert--colored" icon>
                                    <p><span className="bold-text">{this.state.statusMessage}</span>
                                    </p>
                                </Alert>
                            </div>
                        ) : null}
                        <br></br>
                        <form className="form" onSubmit={this.onSubmit}>
                            <div className="col-md-12 text-center">
                                <Button type="submit" color="primary" className="btn-paypal btn-sm" >
                                    {this.state.isLoading ? "Please Wait..." : "Go!"}
                                </Button> &nbsp;&nbsp;&nbsp;
                               </div>
                        </form>
                    </Modal>


                    < Card >
                        <CardBody >
                        </CardBody>

                    </Card>
                </>
            </div>

        )
    }
}