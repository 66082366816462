import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import showResults from '../Form/Show';
import FileUploadDefault from './components/FileUploadDefault';

const FileUploads = ({ t }) => (
  <>
        <h3 className="page-title">{"Reconcile Deposits"}</h3>
    <FileUploadDefault onSubmit={showResults} />
  </>
);
FileUploads.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(FileUploads);
