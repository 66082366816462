import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';
import { Link } from 'react-router-dom';

export class Users extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [{
            key: "idx",
            text: "#",
            TrOnlyClassName: 'tsc',
            className: "tsc",
            align: "left",
        },

        {
            key: "username",
            TrOnlyClassName: 'tsc',
            text: "Username",
            className: "tsc",
            align: "left"
        },
        {
            key: "msisd",
            TrOnlyClassName: 'tsc',
            text: "Phone No",
            className: "tsc",
            align: "left"
        },
        {
            key: "station",
            TrOnlyClassName: 'tsc',
            text: "Station",
            className: "tsc",
            align: "left"
        },

        {
            key: "action",
            text: "Options",
            TrOnlyClassName: 'cell',
            className: "cell",
            width: 160,
            align: "center",
            sortable: false,
            cell: record => {
                return (
                    <Fragment className="center">
                        <button title="Edit Details"
                            className="btn btn-primary btn-sm"
                            style={
                                { marginRight: '10px' }}
                            onClick={
                                () => this.editRecord(record)} >
                            Edit
                        </button>
                        <button className="btn btn-danger btn-sm"
                            title="Delete details"
                            style={
                                { marginRight: '10px' }}
                            onClick={
                                () => { if (window.confirm('Are you sure you wish to delete this record?')) this.onDelete(record) }} >
                            Delete
                        </button>
                    </Fragment>
                );
            }
        }


        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads..."
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }

    componentDidMount() {

        this.getData()

    }

    getData = (queryString = "") => {
        this.setState({ isLoading: true });
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'signature': TOKEN
            },
        };
        axios.get(baseURL + 'user', config)
            .then((response) => {
                console.log("bayo", response.data)
                this.setState({
                    admins: response.data.users,
                    total: response.data.users.length,
                    isLoading: false
                },
                    function () {
                        var data = [];
                        for (let i = 0; i < this.state.admins.length; i++) {

                            let index = { idx: i + 1 };
                            let phone;

                            if (this.state.admins[i].msisdn == null) {
                                phone = { msisd: "Not provided" };
                            } else {
                                phone = { msisd: this.state.admins[i].msisdn };
                            }
                            data.push(Object.assign(index, phone, this.state.admins[i]));

                            this.setState({
                                data: data
                            })
                            console.log("bugs", i);


                        }

                    }
                );
            })

    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    editRecord(record) {
        localStorage.setItem("user_id", record.id)
        localStorage.setItem("username", record.username)
        localStorage.setItem("password", record.password)
        localStorage.setItem("msisdn", record.msisdn)
        localStorage.setItem("role", record.role_id)
        localStorage.setItem("station", record.station)
        window.location.href = "/edituser";
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onDelete(record) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'signature': TOKEN
            }
        };
        let id = record.id

        let formData = {

            "user_id": id.toString(),
        }
        axios.post(baseURL + "user/delete", formData, config).then(response => {
            // eslint-disable-next-line
            if (response.data.code == "0000") {
                this.setState({
                    statusMessage: response.data.message,
                    isShowError: true, isLoading: false, showError: false,
                });
                setTimeout(() => {
                    this.getData()
                    this.setState({
                        statusMessage: "",
                        isShowError: false, isLoading: false, showError: false,
                    });
                }, 2000);

            } else {
                this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
            }
        }).catch(error => {
            console.log('bayoo', error.response)
            this.setState({
                showError: true,
                isShowError: false,
                statusMessage: error.response.data.message,
                submitted: true,
                isLoading: false
            }
                , function () {
                    // console.log('data', error.response.status_message)
                });
        });
    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }
    render() {
        // console.log("Load", isLoading);
        return (
            <div style={{ marginTop: "-20px" }} >
                < >
                    < Card >
                        <CardBody >
                            <div className="panel-body" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Presenters</h3>
                                    </div>
                                    <div className="col-md-6">
                                        <Link to="/createuser">
                                            <Button className="pull-right"
                                                color="primary" outline>Create Presenter</Button><br />
                                        </Link>
                                    </div>
                                </div><br />
                                {this.state.isShowError ? (
                                    <div
                                        color="success"
                                        style={{ fontSize: "13px", color: "green" }}>
                                        {this.state.statusMessage}
                                    </div>

                                ) : null}<br></br>
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.data}
                                    columns={this.columns}
                                    dynamic={true}
                                    total_record={this.state.total}
                                    id="tsc"
                                    loading={this.state.isLoading}
                                />
                            </div>
                        </CardBody>

                    </Card>
                </>
            </div>

        )
    }
}