import React, { Fragment } from 'react';
import ReactDatatable from '@ashvin27/react-datatable';
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../_helpers';
import { TOKEN } from '../../_helpers/token';

import { Link } from 'react-router-dom';

export class SessionList extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [

            {
                key: "presenter",
                TrOnlyClassName: 'tsc',
                text: "Presenter",
                className: "tsc",
                align: "left"
            },
            {
                key: "deposit_amount",
                TrOnlyClassName: 'tsc',
                text: "Deposit Amount",
                className: "tsc",
                align: "left"
            },
            {
                key: "count",
                TrOnlyClassName: 'tsc',
                text: "Deposit Count",
                className: "tsc",
                align: "left"
            },
            {
                key: "winning_amount",
                TrOnlyClassName: 'tsc',
                text: "Winning Amount",
                className: "tsc",
                align: "left"
            },

            {
                key: "winner_count",
                TrOnlyClassName: 'tsc',
                text: "Winner Count",
                className: "tsc",
                align: "left"
            },
            {
                key: "ticket_price",
                TrOnlyClassName: 'tsc',
                text: "Ticket Price",
                className: "tsc",
                align: "left"
            },
            {
                key: "start_time",
                TrOnlyClassName: 'tsc',
                text: "Start Time",
                className: "tsc",
                align: "left"
            },
            {
                key: "end_time",
                TrOnlyClassName: 'tsc',
                text: "End Time",
                className: "tsc",
                align: "left"
            },
            {
                key: "action",
                text: "Options",
                TrOnlyClassName: 'cell',
                className: "cell",
                width: 160,
                align: "center",
                sortable: false,
                cell: record => {
                    return (
                        <Fragment className="center">
                            <button title="Edit Details"
                                className="btn btn-primary btn-sm"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => this.editRecord(record)} >
                                Edit
                            </button>

                            <button className="btn btn-danger btn-sm"
                                title="Delete details"
                                style={
                                    { marginRight: '10px' }}
                                onClick={
                                    () => { if (window.confirm('Are you sure you wish to delete this record?')) this.onDelete(record) }} >
                                Delete
                            </button>
                        </Fragment>
                    );
                }
            }

        ];
        this.config = {
            key_column: "tsc",
            length_menu: [10, 20, 50],
            show_filter: true,
            show_pagination: true,
            pagination: 'advance',
            page_size: 10,
            show_length_menu: true,
            language: {
                loading_text: "Please be patient while data loads..."
            }
        }
        this.state = {
            admins: [],
            isLoading: true,
            showModal: false,
            showError: false,
            isShowError: false,
            showComponent: false,
            hideComponent: false,
            data: [],
        };
    }

    componentDidMount() {
        this.getData();
    }
    getData = (queryString = "") => {
        this.setState({ isLoading: true });
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'signature': TOKEN
            },
        };
        let url = baseURL + "user_session?" + queryString;
        console.log("query", url);
        axios.all([
            axios.get(url, config)
        ]).then(axios.spread((branchResponse) => {
            console.log("bayo", branchResponse.data)
            this.setState({
                admins: branchResponse.data,
                isLoading: false,
            },
                function () {
                    var data = [];
                    console.log("bugs", this.state.admins);
                    if (this.state.admins.sessions.length > 0) {
                        for (let i = 0; i < this.state.admins.sessions.length || 0; i++) {
                            let zero = this.state.admins.sessions[i].deposit_count

                            let count = { count: zero.toString() };

                            data.push(Object.assign(count, this.state.admins.sessions[i]));
                            this.setState({
                                data: data,
                                isLoading: false,

                            })
                            console.log("buxxxgs", data);
                        }
                    } else {
                        this.setState({
                            data: data,
                            isLoading: false,

                        })

                    }
                }
            );
        }))

    }

    tableChangeHandler = data => {
        let queryString = Object.keys(data).map((key) => {
            if (key === "sort_order" && data[key]) {
                return encodeURIComponent("sort_order") + '=' + encodeURIComponent(data[key].order) + '&' + encodeURIComponent("sort_column") + '=' + encodeURIComponent(data[key].column)
            } else {
                return encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
            }

        }).join('&');

        this.getData(queryString);
    }


    editRecord(record) {
        localStorage.setItem("session_id", record.session_id)
        localStorage.setItem("winning_amount", record.winning_amount)
        localStorage.setItem("start_time", record.start_time)
        localStorage.setItem("end_time", record.end_time)
        localStorage.setItem("presenter", record.presenter)
        localStorage.setItem("ticket_price", record.ticket_price)

        window.location.href = "/editsession";

    }

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onDelete(record) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'signature': TOKEN
            }
        };
        let id = record.session_id

        let formData = {

            "session_id": id.toString(),
        }
        axios.post(baseURL + "user_session/delete", formData, config).then(response => {
            // eslint-disable-next-line
            if (response.data.code == "0000") {
                this.setState({
                    statusMessage: response.data.message,
                    isShowError: true, isLoading: false, showError: false,
                });

                window.setTimeout(() => {
                    this.getData();
                    this.setState({
                        statusMessage: "",
                        isShowError: false, isLoading: false, showError: false,
                    });


                }, 2000);

            } else {

                this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
            }
        }).catch(error => {
            console.log('bayoo', error.response)
            this.setState({
                showError: true,
                isShowError: false,
                statusMessage: error.response.data.message,
                submitted: true,
                isLoading: false
            }
                , function () {
                    // console.log('data', error.response.status_message)
                });
        });
    }

    render() {
        // const { isLoading } = this.state;
        // console.log("Load", isLoading);
        return (
            <div style={{ marginTop: "-20px" }} >
                < >
                    < Card >
                        <CardBody >
                            <div className="panel-body" >
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Sessions</h3>
                                    </div>

                                    <div className="col-md-6">
                                        <Link to="/createsession">
                                            <Button className="pull-right"
                                                color="primary" outline>Create Session</Button><br />
                                        </Link>
                                    </div>
                                </div>
                                <div className="text-left">
                                    Total Deposit Amount as at Now: <   b>{this.state.admins.total_deposit_amount}</b> || Total Deposit Counts: <b>{this.state.admins.total_deposit_count}</b>
                                </div>

                                {this.state.isShowError ? (
                                    <div
                                        color="success"
                                        style={{ fontSize: "13px", color: "green" }}>
                                        {this.state.statusMessage}
                                    </div>
                                ) : null}
                                <br/>
                                <ReactDatatable
                                    config={this.config}
                                    records={this.state.data}
                                    columns={this.columns}
                                    dynamic={true}
                                    id="tsc"
                                    loading={this.state.isLoading}
                                    total_record={this.state.admins.session_count}
                                    onChange={this.tableChangeHandler} />
                            </div>
                        </CardBody>

                    </Card>
                </>
            </div>

        )
    }
}