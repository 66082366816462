import * as React from 'react';
import '../../_assets/css/file.css';
import Select from 'react-select';
import axios from 'axios';

import { TOKEN } from '../../_helpers/token';
import { baseURL } from '../../_helpers';
import { Card, CardBody, Col, Button } from 'reactstrap';
import * as moment from 'moment';

export class EditSession extends React.Component {
    constructor(props) {
        super(props)
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            phone_number: '',
            showError: false,
            isShowError: false,
        }
    }


    async componentDidMount() {

        this.setState({
            paybill: localStorage.getItem("paybill")
        })
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'signature': TOKEN
            },
        };
        axios.get(baseURL + 'user', config)
            .then((response) => {

                this.setState({
                    user: response.data.users,
                    isLoading: false,
                },
                    function () {
                        var data = [];
                        for (let i = 0; i < this.state.user.length || 0; i++) {
                            // let index = { idx: i + 1 };
                            // deposit_count
                            // eslint-disable-next-line
                            if (this.state.user[i].username == localStorage.getItem("presenter")) {
                                data.push(Object.assign(this.state.user[i]));

                                this.setState({
                                    data: data,
                                    isLoading: false,

                                })
                            }
                            console.log("buxxxgs", data);
                        }

                    }
                );
            })



        let startDate = moment(localStorage.getItem("start_time")).format("yyyy-MM-DDTHH:mm:" + "SS")
        let endDate = moment(localStorage.getItem("end_time")).format("yyyy-MM-DDTHH:mm:" + "SS")

        this.setState({
            isLoading: false,
            ticket_price: localStorage.getItem("ticket_price"),
            winning_amount: localStorage.getItem("winning_amount"),
            start_time: startDate,
            end_time: endDate
        });

        // alert(localStorage.getItem("end_time"))


        console.log("BRIAN", this.state.user)

    }
    USERS() {
        return (this.state.data && (this.state.data.length > 0 || this.state.data.length === 0) &&
            this.state.data.map((countyItem, i) =>
                ({ label: countyItem.username + " (" + countyItem.station + " station)", value: countyItem.id })))

    }

    onSelectuser = value => {
        this.setState({ presenter_id: value.value.toString() });
    };

    onSubmit(e) {
        e.preventDefault();

        this.setState({
            isLoading: true
        });

        const config = {
            headers: {
                'Content-Type': 'application/json',
                'signature': TOKEN
            },
        };
        let id = localStorage.getItem("session_id")
        // alert(moment(this.state.end_time).format("yyyy-MM-DD HH:MM:" + "SS"))
        let formData = {

            "session_id": id.toString(),
            "paybill": this.state.paybill,
            "presenter_id": this.state.presenter_id,
            "ticket_price": this.state.ticket_price,
            "winning": this.state.winning,
            // eslint-disable-next-line
            "start_time": moment(this.state.start_time).format("yyyy-MM-DD HH:mm:" + "SS"),
            // eslint-disable-next-line
            "end_time": moment(this.state.end_time).format("yyyy-MM-DD HH:mm:" + "SS")
        }
        console.log("usr data", formData)

        axios.post(baseURL + 'user_session/update', formData, config)

            .then((response) => {
                console.log('bayoo', response.data)
                // eslint-disable-next-line
                if (response.data.code == "0000") {
                    this.setState({
                        statusMessage: response.data.message, paybill: null, presenter_id: null, start_time: null, end_time: null,
                        isShowError: true, isLoading: false, showError: false,
                    });
                    window.setTimeout(function () {
                        window.location.href = "/session";
                    }, 2000);

                } else {

                    this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });
                }
            }).catch(error => {
                console.log('bayoo', error.response)
                this.setState({
                    showError: true,
                    isShowError: false,
                    statusMessage: error.response.data.message,
                    submitted: true,
                    isLoading: false
                }
                    , function () {
                        // console.log('data', error.response.status_message)
                    });
            });

    }
    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    home() {
        window.location.href = "/session";
    }


    render() {
        const { showComponent } = this.state;
        const { hideComponent } = this.state;

        return (
            <div style={{ marginTop: "-20px" }} >

                {showComponent && (
                    <div>

                        {this.state.showComponent ?
                            <peopleView /> : null
                        }

                    </div>
                )}

                {!hideComponent && (
                    <>
                        <Card>
                            <CardBody>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h3>Edit Session</h3>
                                    </div>
                                    <div className="col-md-6">

                                        <Button className="pull-right"
                                            color="primary" outline onClick={this.home}>View Sessions</Button><br /> <br /><br />

                                    </div>
                                </div>
                                <div className="card__title">
                                    <h5 className="bold-text">Fill the Below Field to edit user session: {localStorage.getItem("session_id")} </h5>
                                </div>
                                {this.state.showError ? <div style={{ color: 'red' }}>
                                    {this.state.statusMessage}
                                </div> : null}
                                {this.state.isShowError ? (
                                    <div
                                        color="success"
                                        style={{ fontSize: "13px", color: "green" }}>
                                        {this.state.statusMessage}
                                    </div>

                                ) : null}<br></br>
                                <form className="form" onSubmit={this.onSubmit}>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Select Presenter</label>
                                            <Select
                                                options={this.USERS()}
                                                onChange={this.onSelectuser}
                                                tabSelectsValue={false}
                                                value={this.state.onSelectuser}
                                                className='drop'
                                            />
                                            <br />
                                        </div>
                                    </div>


                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Start Time</label>
                                            <input id="input" type="datetime-local" className="form-control"
                                                name="start_time" required placeholder="Start Time"
                                                value={this.state.start_time} onChange={this.handleChange} />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">End Time</label>
                                            <input id="input" type="datetime-local" className="form-control"
                                                name="end_time" required placeholder="End Time"
                                                value={this.state.end_time} onChange={this.handleChange} />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Ticket Price</label>
                                            <input id="input" type="text" className="form-control input-md"
                                                name="ticket_price" required placeholder="Enter Amount"
                                                value={this.state.ticket_price} onChange={this.handleChange} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label className="form-label">Winning Price</label>
                                            <input id="input" type="text" className="form-control input-md"
                                                name="winning" required placeholder="Enter Winning Amount"
                                                value={this.state.wining} onChange={this.handleChange} />
                                        </div>
                                    </div>

                                    <br />
                                    <div className="col-md-12">
                                        <Button type="submit" color="primary" className="btn-paypal btn-lg pull-right  text-white " >
                                            {this.state.isLoading ? "Please Wait..." : "Submit"}</Button> &nbsp;&nbsp;&nbsp;
                                       </div>
                                </form>
                            </CardBody>
                        </Card>
                    </>

                )
                }

            </div>
        )

    }

}
