import React from 'react';
// import '../../_assets/css/file.css';
import { Form } from "react-bootstrap";
import axios from 'axios';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { baseURL } from '../../../_helpers';
import { TOKEN } from '../../../_helpers/token';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Input from 'react-phone-number-input/input'
import { Link } from 'react-router-dom';
import Alert from '../../../shared/components/Alert';
import Select from 'react-select';
import { submit } from 'redux-form';
import { MDBCloseIcon } from "mdbreact"
import RefreshIcon from 'mdi-react/RefreshCircleIcon';
import * as moment from 'moment';
import Modal from 'react-modal';
import "./style2.css"
import img from '../../../_assets/img/logo.png'
import img2 from '../../../_assets/img/logo.png'
import img3 from '../../../_assets/img/logo.png'
import leftimage from '../../../_assets/img/homee.jpg'
import play from '../../../_assets/img/play.svg'
import homepic from '../../../_assets/img/home.svg'
import phone from '../../../_assets/img/phone.svg'
import twitter from '../../../_assets/img/twitter.svg'

// import Navbar from 'react_navbar_responsive';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
} from "react-router-dom";
import { Navbar, Nav, NavDropdown, FormControl } from 'react-bootstrap'

import img4 from '../../../_assets/img/logo.png'

import logo from '../../../_assets/img/logo.png'
import back from '../../../_assets/img/logo.png'

class Home extends React.Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
    };
    constructor(props) {
        super(props)
        this.state = {
            showComponent: false,
            hideComponent: true,
            moreinfo: false,
        };
        this._onButtonClick = this._onButtonClick.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this._onSubmitDeposit = this._onSubmitDeposit.bind(this);
        this.toggleModalFloat = this.toggleModalFloat.bind(this);
        this.closeModalHowtoPlay = this.closeModalHowtoPlay.bind(this);
        this.toggleModalHowtoPlay = this.toggleModalHowtoPlay.bind(this);
        this.toggleModalFloat1 = this.toggleModalFloat1.bind(this);
        this.closeModalFloat1 = this.closeModalFloat1.bind(this);
        this.state = {
            isShowError: false,
            statusMessage: '',
            isOpenFloat: false,
            howtoplay: false,
            email_id: "",
            isOpenPhone: false,
            agent_ids: null,
            showError: false
        }
    }

    handleChangeRegion = (event) => {
        this.setState({ customer_type: event.target.value });
    };


    handleChangeType = (event) => {
        this.setState({ title: event.target.value });
    };
    toggleModalHowtoPlay() {
        this.setState({
            howtoplay: true
        });
    };

    toggleModalFloat() {
        this.setState({
            isOpenFloat: true
        });
    };

    closeModalFloat = e => {
        this.setState({
            isOpenFloat: false,
        });
    };

    closeModalHowtoPlay = e => {
        this.setState({
            howtoplay: false,
        });
    };

    toggleModalFloat1() {
        this.setState({
            isOpenPhone: true
        });
    };

    closeModalFloat1 = e => {
        this.setState({
            isOpenPhone: false,
        });
    };

    onSubmit(e) {
        e.preventDefault();
    }

    _onSubmitDeposit() {

        // alert(this.state.agent_email)
        let formData = {
            "amount": this.state.amount,
            "msisdn": this.state.msisdn.replaceAll("+", ""),
            "station": this.state.station
        }

        this.setState({
            isLoading: true
        })

        axios.post(baseURL + 'initiate_deposit', formData, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': TOKEN
            },
        }).then((response) => {
            // alert(JSON.stringify(formData))
            if (response.data.code === "0000") {
                this.setState({ statusMessage: response.data.message, showError: false, isShowError: true, isLoading: false });
                window.scrollTo(0, 0)
                window.setTimeout(() => {
                    this.setState({ isShowError: false });
                }, 2000);
            } else {
                this.setState({ statusMessage: response.data.message, showError: true, isShowError: false, isLoading: false });

                window.setTimeout(() => {
                    this.setState({
                        isLoading: false,
                        isShowError: false,
                        statusMessage: "",
                        showError: false
                    });
                }, 2000);
            }
        }).catch((error) => {
            console.log('bayoo', error)
            this.setState({
                statusMessage: error.response === undefined ? "Check your Internet" : error.response.data.message, showError: true, isShowError: false, isLoading: false
            });
            window.setTimeout(() => {
                this.setState({
                    isLoading: false,
                    isShowError: false,
                    showError: false,
                    errorShow: false
                });
            }, 2000);
        })

    }


    _onButtonClick() {
        this.setState({
            showComponent: true,
            hideComponent: true,
            moreinfo: false
        });
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    componentDidMount() {

        this.getData();

    }


    getData = (queryString = "") => {
        let formData = {
            "session_id": TOKEN,
        }
        let axiosConfig = {
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                "Authorization": TOKEN
            }
        };
        let url = baseURL + "station"

        axios.all([
            axios.get(url, formData),
        ]).then(axios.spread((stationResponse) => {
            this.setState({
                stations: stationResponse.data.stations
                ,
            }, function () {
                // this.getData();

                var data = []
            })
        })).catch(error => {
            console.log('bayoo', error.response)

        })
    }


    Banks() {
        return (
            this.state.stations &&
            (this.state.stations.length > 0 || this.state.stations.length === 0) &&
            this.state.stations.map((countyItem, i) => ({
                label: countyItem.name,
                value: countyItem.id,
            }))
        );
    }






    onSelectChangesBank = value => {


        if (value.value != null) {
            if (this.state.amount > 0) {
                this.setState(
                    {
                        station_id: value.value.toString(),
                        station: value.label.toString(),

                        mreference: require("randomstring").generate({
                            length: 10,
                            charset: 'alphanumeric',
                            capitalization: 'lowercase'
                        })
                    });
            } else {
                alert("Enter valid amount")
                // window.location.reload();
            }
        } else {

        }
    };



    // you can call this function anything
    onSuccess = (reference) => {
        // Implementation for whatever you want to do with reference and after success call.
        console.log(reference);
    };

    // you can call this function anything
    onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        console.log('closed')
    }





    handlePaystackSuccessAction = (reference) => {
        console.log("success", JSON.stringify(reference));
        //post to paystack requests db

        this.closeModalFloat()
        // alert(reference)
        // this._onSubmitDeposit(reference.reference)
    };


    render() {
        const { showComponent } = this.state;
        return (

            <div className="col-md-12" style={{ backgroundImage: `url(${leftimage})`, backgroundColor: `white`, height: "100vh" }} >

                <div className="col-md-12" style={{ backgroundColor: `url(${leftimage})`, height: "80vh" }}>


                    <div className="col-12">
                        <Navbar expand="sm">
                            <Navbar.Brand href="#" ><img
                                src={logo}
                                width="10"
                                height="200"
                                className="d-inline-block"
                            /></Navbar.Brand>

                            <Navbar.Toggle aria-controls="basic-navbar-nav" />

                            <Navbar.Collapse id="basic-navbar-nav" style={{ marginTop: "20dp" }}>
                                <Nav className="ml-auto" style={{ fontSize: '1em', color: "black" }}>
                                    <Nav.Link href="#" style={{ fontSize: '1.8em', color: "black", marginLeft: "16px" }} >Home</Nav.Link>
                                    <Nav.Link href="ourwinners" style={{ fontSize: '1.8em', color: "black", marginRight: "16px", marginLeft: "20px" }} >Vencedores</Nav.Link>
                                    <Nav.Link href="#" onClick={(this.toggleModalHowtoPlay)} style={{ fontSize: '1.8em', color: "black", marginRight: "16px", marginLeft: "20px" }} >How to Play</Nav.Link>

                                </Nav>
                            </Navbar.Collapse>

                        </Navbar>

                    </div>
                    <div className="row">

                        <div className="col-12">

                            <h2 style={{}} className="margin"> <span className="first-letter"><b>GANHE</b></span><l style={{ color: "black" }}>
                                uma viagem totalmente financiada <br />variedade de mercadorias  <br /> especiais e dinheiro INSTANTÂNEO!</l></h2>

                            <form className="form" >
                                <div className="col-md-6">
                                    <div className="form-group pull-left" >
                                        <div onClick={this.toggleModalFloat} className="buttontop"  >
                                            <img src={play} style={{ cursor: "pointer", width: "70%", height: "70%" }} />
                                        </div>
                                    </div>

                                </div>
                                <div className="col-md-6">

                                    <div onClick={this.toggleModalFloat}  >
                                        {/* <img src={homepic} /> */}
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>

                <div className="row" style={{ backgroundColor: "white" }}>
                    <div className="col-12 text-center">
                        <h2 className="margin"><l style={{ color: "#3EB5E5" }}>
                            Compre os seus bilhetes e entre no próximo sorteio</l></h2>
                        <h2 className="margin"> <span className="first-letter"><b>Sorteio semanal de 1 MILHÃO  </b></span></h2>

                        {/* <div style={{ backgroundImage: `url(${leftimage})`, backgroundColor: `white` }} > */}

                        {/* </div> */}
                        <br />
                        {/* <img className="fill-window" src={img} /> */}
                    </div>
                </div>
                <div style={{ backgroundColor: "#68BF65" }}>
                    <div className="col-10 offset-1 row" >
                        <div className="col-md-4">

                            <br />
                            <h4 style={{ color: "white", marginBottom: "10px" }}><b>Super Sorte</b></h4>

                            <h4 style={{ color: "white" }}>Página Inicial</h4>
                            <h4 style={{ color: "white" }}><Link to="ourwinners">Vencedores</Link> </h4>
                            {/* <h4 style={{ color: "white", cursor: "pointer" }} onClick={this.toggleModalHowtoPlay}>Sobre Nós</h4> */}
                        </div>
                        <div className="col-md-4">
                            <br />
                            <h4 style={{ color: "white", marginBottom: "10px" }}><b>Como Jogar</b></h4>
                            <p style={{ color: "white" }}>
                                Os clientes podem comprar ingressos a 10MT e quantos
                                ingressos um jogador quiser depositando via Mpesa. O
                                número do celular será o identificador exclusivo dos
                                participantes. Um número de celular pode comprar vários
                                ingressos, aumentando assim sua chance de ser um
                                vencedor. Será enviada uma confirmação de compra via
                                SMS confirmando o número do bilhete, o bilhete será gerado
                                aleatoriamente pelo sistema.
                            </p>
                        </div>


                        <div className="col-md-4">
                            <br />
                            <h4 style={{ color: "white", marginBottom: "10px" }}><b>Social Platforms</b></h4><br />

                            <a href="#">
                                <i className="fa fa-facebook" style={{ color: "white", marginRight: "20px", marginBottom: "10px" }}></i></a>
                            <a href="#"> <i className="fa fa-instagram" style={{ color: "white", marginRight: "20px", marginBottom: "10px" }}></i></a>
                            <br /> <br />
                            <h4 style={{ color: "white" }}><i className="fa fa-phone" /> +258 85 279 297 3</h4>
                            {/* <h4 style={{ color: "white" }}><i className="fa fa-envelope" /> support@luckybonanza.ng</h4> */}


                        </div>

                        <br />



                        {/* <img src={img3} /> */}

                    </div>

                </div>


                <Modal
                    isOpen={this.state.howtoplay}
                    onRequestClose={e => {
                        this.closeModalHowtoPlay(e);
                    }}
                    contentLabel="My dialog"
                    className="mymodal"
                    onAfterOpen={() => {
                        document.body.style.overflow = 'hidden';
                    }}
                    onAfterClose={() => {
                        document.body.removeAttribute('style');
                    }}
                    overlayClassName="myoverlay"
                    closeTimeoutMS={500}>

                    <MDBCloseIcon onClick={this.closeModalHowtoPlay} />
                    <h4><b>Como Jogar</b></h4><br />


                    <p>
                        <strong>Como Jogar – </strong>

                            Os clientes podem comprar ingressos a 10MT e quantos
                            ingressos um jogador quiser depositando via Mpesa. O
                            número do celular será o identificador exclusivo dos
                            participantes. Um número de celular pode comprar vários
                            ingressos, aumentando assim sua chance de ser um
                            vencedor. Será enviada uma confirmação de compra via
                            SMS confirmando o número do bilhete, o bilhete será gerado
                            aleatoriamente pelo sistema.
</p>
                    <p>
                        Terms and Conditions apply.
                     </p>

                    <br></br>
                </Modal>


                <Modal
                    isOpen={this.state.isOpenFloat}
                    onRequestClose={e => {
                        this.closeModalFloat(e);
                    }}
                    contentLabel="My dialog"
                    className="mymodal"
                    onAfterOpen={() => {
                        document.body.style.overflow = 'hidden';
                    }}
                    onAfterClose={() => {
                        document.body.removeAttribute('style');
                    }}
                    overlayClassName="myoverlay"
                    closeTimeoutMS={500}>

                    <MDBCloseIcon onClick={this.closeModalFloat} />
                    <h4><b>Make Deposit</b></h4><br />
                    {this.state.showError ? <div >
                        <Alert color="danger" className="alert--colored" icon>
                            <p><span className="bold-text">{this.state.statusMessage}</span>
                            </p>
                        </Alert>
                    </div> : null}
                    {this.state.isShowError ? (
                        <div>
                            <Alert color="success" className="alert--colored" icon>
                                <p><span className="bold-text">{this.state.statusMessage}</span>
                                </p>
                            </Alert>
                        </div>
                    ) : null}
                    <br></br>
                    <form className="form" onSubmit={this.onSubmit}>
                        <div className="col-md-12">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-12">

                                        <label className="form-label">Amount</label>
                                        <input id="input" type="number" className="form-control"
                                            name="amount" placeholder='Amount'
                                            value={this.state.amount} onChange={this.handleChange} />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-12">
                                        <label className="form-label">Station</label>

                                        <Select
                                            isClearable
                                            options={this.Banks()}
                                            // onInputChange={this.loadOptions}
                                            placeholder="Select Station"
                                            autosize={true}
                                            isRequired
                                            onChange={this.onSelectChangesBank}
                                            // isLoading={this.state.isLoading}
                                            // // noOptionsMessage={() => 'nothing found'}
                                            // loadingMessage={() => 'searching...'}   //minor type-O here
                                            menuPortalTarget={document.body}
                                            name="namffe"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-md-12">

                                        <label className="form-label">Phone Number</label>

                                        <Input
                                            country="MZ"
                                            international
                                            withCountryCallingCode
                                            className="form-control"
                                            placeholder="Ваш телефон"
                                            name="msisdn"
                                            id="input"
                                            value={this.state.msisdn}
                                            onChange={value => this.setState({ msisdn: value })} />


                                    </div>
                                </div>
                            </div>

                            <br />
                            {this.state.station_id != null ?
                                <>
                                    <button
                                        type="submit" color="primary" outline
                                        className="btn btn-primary float-right btn-sm"
                                        onClick={() => {
                                            this._onSubmitDeposit()
                                        }
                                        }>
                                        {this.state.isLoading ? "Please Wait..." : "Proceed"}

                                    </button>
                                </>
                                : null}

                        </div>

                    </form>

                </Modal>


            </div >
        )
    }
}
export default withTranslation('common')(Home);
